<template>
  <div class="outter">
    <div class="ad_banner">
      <img src="../assets/image/news/news.png" alt="">
    </div>
    <div class="list">
      <ul>
        <li @click="to('/detail?id='+ item.id)" v-for="(item,key) in newsList" :key="key">
          <div class="date">
            <span>{{item.month}}</span>
            <span>{{item.day}}</span>
          </div>
          <div class="item clearFix">
            <img :src="item.imgUrl" alt="">
            <div class="item-content">
              <div class="item-content-title clearFix">
                <span class="fl word-weight ellipsis1">{{item.title}}</span>
                <a class="fl" herf=“” @click="to('/detail?id='+ newsList[1].id)">查看详情<img src="../assets/image/arror_color.png" alt=""></a>
              </div>
              <p class="ellipsis4">{{item.desc}}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="paging" v-if="paging.totalPages/1 > 1">
      <el-pagination background layout="prev, pager, next" @current-change='changePaging' :current-page='paging.current/1' :page-count="paging.totalPages/1">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Pagination } from 'element-ui'
export default {
  name: 'news',
  data() {
    return {
      newsList: '',
      paging: ''
    }
  },
  created() {
    let newsList = JSON.parse(sessionStorage.getItem('list')).body
    let paging = JSON.parse(sessionStorage.getItem('list')).paging
    this.newsList = newsList
    this.paging = paging
  },
  methods: {
    to(e) {
      window.scrollTo(0, 0)
      console.log(e,this.$route.path)
      if (e != this.$route.path) {
        this.$router.push({
          path: e
        })
      }
    },
    changePaging(e) {
      Vue.prototype
        .$post('/news/list', {
          page: e,
          limit: 5
        })
        .then(res => {
          this.newsList = res.data.body
          this.paging = res.data.paging
        })
    }
  }
}
</script>
<style lang="less" scoped>
@import '../assets/css/mixin.less';
.list {
  width: 1200px;
  margin: auto;
  ul {
    position: relative;
    padding: 77px 0 0;
    margin-bottom: 69px;
    li {
      padding-left: 116px;
      width: 100%;
      box-sizing: border-box;
      cursor: pointer;
      margin: 0 0 28px;
      position: relative;

      .date {
        position: absolute;
        left: 0;
        span {
          display: block;
          width: 70px;
          height: 48px;
          text-align: center;
          line-height: 48px;
        }
        span:first-child {
          color: #fff;
          background: #00cdb7;
          font-size: 18px;
        }
        span:last-child {
          color: #454545;
          background: #fff;
          font-size: 18px;
          border: 1px solid #00cdb7;
          box-sizing: border-box;
        }
      }
      .item {
        position: relative;
        border-bottom: 1px solid #eee;
        >img {
          width: 329px;
          height: 203px;
          float: left;
        }
        .item-content {
          padding: 22px 0 0 30px;
          float: left;
          width: 741px;
          box-sizing: border-box;

          &-title{
            .font(18px, #333);
            padding: 0 0 19px;
            width: 620px;

            a {
              position: absolute;
              right: 0;
              top: 24px;
              .font(14px, #00B095);
              .wh(auto, auto);
              
              img {
                padding: 0 0 4px 10px;
              }
            }
          }
        }
        div {
          p {
            color: #454545;
            font-size: 13px;
            line-height: 29px;
            width: 741px;
            text-align: justify;
          }
        }
      }
    }
  }
}
.paging {
  width: 1200px;
  margin: 0 auto 54px;
  .el-pagination {
    text-align: center;
    .btn-prev {
      border-radius: 50%;
    }
    .el-pager {
      li.number {
        width: 35px;
        height: 35px;
        background: #00cdb7;
        border-radius: 50%;
      }
    }
  }

  .circle {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: #f9f9f9;
    color: #454545;
    text-align: center;
    line-height: 55px;
  }
}
</style>
<style lang='less'>
.paging {
  .el-pagination {
    text-align: center;
    button.btn-prev {
      border-radius: 50%;
      width: 35px;
      height: 35px;
      background: #d9f8f4;
      i {
        font-size: 18px;
        color: #09b7a1;
      }
    }
    button.btn-next {
      border-radius: 50%;
      width: 35px;
      height: 35px;
      background: #d9f8f4;
      i {
        font-size: 18px;
        color: #09b7a1;
      }
    }
    button.btn-prev:disabled {
      background: #f9f9f9;
      i {
        color: #454545;
      }
    }
    button.btn-next:disabled {
      background: #f9f9f9;
      i {
        color: #454545;
      }
    }
    .el-pager {
      li {
        width: 35px;
        height: 35px;
        background: #f9f9f9;
        border-radius: 50%;
        font-size: 18px;
        font-weight: normal;
        line-height: 35px;
      }
      li.number.active {
        background: #00cdb7;
      }
    }
  }
}
</style>
